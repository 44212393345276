


























































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { Log } from "@/resources/interfaces";
import { LogResource } from "@/resources";
import ws from "@/helpers/webSocket";
import { confirm } from "@/helpers/sweetAlert";

@Component
export default class LogsCard extends Vue {
  @Prop()
  booking?: string;

  @Prop()
  user?: string;

  @Prop({ type: Date, default: () => new Date() })
  loaded!: Date;

  logs: Log[] = [];

  newLog: Partial<Log> | null = null;

  @Watch("loaded", { immediate: true }) onLoaded() {
    this.loadLogs();
  }

  async loadLogs() {
    const cond: Record<string, any> = { limit: false };
    if (this.booking) {
      cond.booking = this.booking;
    } else if (this.user) {
      cond.user = this.user;
    } else {
      return;
    }
    this.logs = await LogResource.query(cond);
    if (!this.booking) {
      this.logs = this.logs.filter(
        log => !["enter", "exit"].includes(log.type)
      );
    }
  }

  async addLog() {
    this.newLog = {
      booking: this.booking,
      user: this.user,
      type: "remarks",
      content: ""
    };
    await this.$nextTick();
    ((this.$refs.newLogInput as Vue).$el as HTMLInputElement).focus();
  }

  async createLog() {
    if (!this.newLog) return;
    const log = await LogResource.save(this.newLog);
    this.logs.unshift(log);
    this.newLog = null;
  }

  logWaivable(log: Log) {
    if (this.$user.can("DEVELOP")) return true;
    if (this.$user.id === log.createdBy?.id) return true;
    return false;
  }

  async waiveLog(log: Log, waived = true) {
    if (
      waived &&
      !(await confirm(
        "确定作废此条日志吗？",
        "日志不会被删除，本操作可以复原",
        "作废",
        "error"
      ))
    ) {
      return;
    }
    log.waived = waived;
    this.logs = [...this.logs];
    await LogResource.update({ id: log.id }, { waived });
  }

  listenNewLog() {
    ws.$on("message", (msg: string) => {
      const [action, type, id] = msg.split(" ");
      if (action === "newLog") {
        if (
          (type === "user" && this.user === id) ||
          (type === "booking" && this.booking === id)
        ) {
          this.loadLogs();
        }
      }
    });
  }

  created() {
    this.listenNewLog();
  }
}
