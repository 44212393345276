






































































import { StaffResource } from "@/resources";
import { Staff } from "@/resources/interfaces";
import List from "@/components/List";

export default class StaffList extends List<Staff> {
  name = "staff";
  resource = StaffResource;
  currentSort = "onboardAt";
  searchQuery = { active: true };
}
