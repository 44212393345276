































import { UserResource } from "@/resources";
import { User } from "@/resources/interfaces";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component
export default class MergeCustomerCard extends Vue {
  @Prop()
  customer!: User;

  @Prop()
  value!: boolean;

  mergingCustomer: User | null = null;
  searchTerm = "";

  fields: {
    field:
      | "mobile"
      | "name"
      | "childName"
      | "childGender"
      | "childBirthday"
      | "childPhotoUrl"
      | "store"
      | "registeredAt"
      | "unionid";
    label: string;
    pick: null | 0 | 1;
    disabled?: boolean;
  }[] = [
    { field: "mobile", label: "手机号", pick: null },
    { field: "name", label: "会员姓名", pick: 0 },
    { field: "childName", label: "孩子姓名", pick: 0 },
    { field: "childGender", label: "孩子性别", pick: 0 },
    { field: "childBirthday", label: "孩子生日", pick: 0 },
    { field: "childPhotoUrl", label: "照片", pick: 0 },
    { field: "store", label: "门店", pick: 0 },
    { field: "registeredAt", label: "注册渠道", pick: 0 },
    { field: "unionid", label: "微信小程序", pick: null, disabled: true }
  ];

  get pickTargetMobile() {
    return this.fields.find(i => i.field === "mobile")?.pick;
  }

  @Watch("pickTargetMobile") onPickTargetMobile(v: boolean) {
    const unionidFieldItem = this.fields.find(i => i.field === "unionid");
    if (!unionidFieldItem) return;
    unionidFieldItem.pick = v ? 1 : 0;
  }

  @Watch("searchTerm") async onSearch(t: string) {
    if (!t) {
      this.mergingCustomer = null;
      return;
    }
    const users = await UserResource.query({ keyword: t });
    if (users.length === 1) {
      this.mergingCustomer = users[0];
      if (this.mergingCustomer.id === this.customer.id) {
        this.searchTerm = "";
        this.mergingCustomer = null;
        return;
      }
      this.fields.forEach(f => {
        if (!this.customer[f.field] && this.mergingCustomer?.[f.field]) {
          f.pick = 1;
        }
      });
    } else {
      this.mergingCustomer = null;
      this.fields.forEach(f => (f.pick = 0));
    }
  }

  fieldValue(value: any, field: string) {
    if (field === "store") {
      if (typeof value === "string") {
        return this.$stores.find(s => s.id === value)?.name || "-";
      }
      return value?.name || "-";
    }
    if (field === "unionid") {
      return value ? `已激活 (${value.substr(-4)})` : "-";
    }
    if (field === "childPhotoUrl") {
      return value ? "已上传" : "-";
    }
    return value || "-";
  }

  cancel() {
    this.searchTerm = "";
    this.mergingCustomer = null;
    this.$emit("input", false);
  }

  async confirm() {
    if (!this.mergingCustomer) return;
    const target = this.mergingCustomer;
    await UserResource.update(
      {
        id: this.customer.id,
        mergeCustomer: this.mergingCustomer?.id,
        pickTargetMobile: this.pickTargetMobile
      },
      this.fields.reduce((set, item) => {
        if (item.pick === 1) {
          set[item.field] = target[item.field];
        }
        return set;
      }, {} as Record<string, any>)
    );
    this.searchTerm = "";
    this.mergingCustomer = null;
    this.$emit("input", false);
    this.$emit("merged");
  }
}
