










































































































import Vue from "vue";
import { Watch, Component, Prop } from "vue-property-decorator";
import { BookingsCard, Membership, Poster } from "@/components";
import { BookingResource, UserResource } from "@/resources";
import {
  User,
  Store,
  Booking,
  PermissionKey,
  Permission
} from "@/resources/interfaces";
import ObjectSelect from "@/components/ObjectSelect.vue";
import { confirm } from "@/helpers/sweetAlert";

@Component({
  components: {
    BookingsCard,
    Membership,
    Poster,
    ObjectSelect
  }
})
export default class StaffProfile extends Vue {
  user: Partial<User> = { tags: [] };
  userBookings: Booking[] = [];

  @Prop({ default: false })
  add!: boolean;

  get readonly() {
    if (!this.$user.can("STAFF") || this.user.tags?.includes("pr")) {
      return true;
    }
    return false;
  }

  get creatable() {
    return !this.user.id && this.user.mobile?.length === 11;
  }

  can(...permissions: PermissionKey[]) {
    return permissions.every(permission =>
      this.user.role?.permissions?.includes(Permission[permission])
    );
  }

  async remove() {
    if (
      await confirm("确定删除该职员吗？", "删除后无法恢复", "确定删除", "error")
    ) {
      await UserResource.delete({ id: this.user.id });
      this.$router.back();
    }
  }

  async save() {
    this.user = await UserResource.save(this.user);
    this.$notify({
      message: "保存成功",
      icon: "check",
      type: "success"
    });
    if (this.add) {
      this.$destroy();
      this.$router.replace(`/profile/${this.user.id}`);
    }
  }

  async getUser() {
    this.user = await UserResource.get({ id: this.$route.params.id });
    return this.user;
  }

  createBooking(type = "play") {
    this.$router.push(`/booking/${type}/add?customer=${this.user.id}`);
  }

  @Watch("user.mobile") async onUserMobileUpdate() {
    if (!this.user.id && this.user.mobile?.length === 11) {
      const [user] = await UserResource.query({ mobile: this.user.mobile });
      if (user) {
        this.$destroy();
        this.$router.replace(`/user/${user.id}`);
      } else {
        this.$notify({
          message: "没有匹配手机号的职员，请继续创建职员",
          type: "success",
          icon: "add_alert"
        });
      }
    }
  }

  @Watch("user.store") onUserStoreUpdate(store: Store | false) {
    if (typeof store === "object" && store) {
      // @ts-ignore
      this.user.store = this.user.store.id;
    } else if (store === false) {
      this.user.store = null;
    }
  }

  async created() {
    if (!this.add) {
      this.$set(this.user, "id", this.$route.params.id);
      this.getUser();
      this.userBookings = await BookingResource.query({
        customer: this.user.id,
        limit: 50
      });
    }
  }
}
