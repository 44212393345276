


































































import { Component } from "vue-property-decorator";
import List from "@/components/List";
import { UserResource } from "@/resources";
import { User } from "@/resources/interfaces";

@Component
export default class StaffList extends List<User> {
  name = "user";
  resource = UserResource;
  stats: {
    totalBalance?: number;
    totalBalanceDeposit?: number;
    totalValidCardBalance?: number;
    totalValidCardBalanceDeposit?: number;
  } = {};
  async queryData() {
    const queriedData = await (List as any).options.methods.queryData.call(
      this
    );
    if (!queriedData) return;
    return queriedData;
  }
  showDetail(item: User) {
    this.$router.push(`/profile/${item.id}`);
  }
  showCreate() {
    this.$router.push(`/profile/add`);
  }
  async created() {
    this.searchQuery = { role: "staff" };
  }
}
