








import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class CopyId extends Vue {
  @Prop({ type: String })
  name!: string;

  @Prop({ type: String })
  value!: string;

  @Prop({ type: String })
  no?: string;
}
