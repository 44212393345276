







































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { StaffResource } from "@/resources";
import { Staff } from "@/resources/interfaces";
import { StoreSelect } from "@/components";

@Component({
  components: {
    StoreSelect
  }
})
export default class StaffDetail extends Vue {
  staff: Partial<Staff> = { id: "" };
  async save() {
    this.staff = await StaffResource.save(this.staff);
    this.$notify({
      message: "保存成功",
      icon: "check",
      type: "success"
    });
    if (this.$route.params.id === "add") {
      this.$router.replace(`/staff/${this.staff.id}`);
    }
  }
  async mounted() {
    if (this.$route.params.id !== "add") {
      this.staff = await StaffResource.get({ id: this.$route.params.id });
    }
  }
}
